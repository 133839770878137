import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const environmentalProtection = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Environmental protection"
      lang={lang}
      translationPL="/ochrona-srodowiska/"
      translationDE="/de/umweltschutz/"
    >
      <SmallBanner title="Environmental protection" lang={lang} />
      <section className="text-section text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Environmentally - friendly company
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                PalettenWerk is committed to a reasonable and safe impact on the
                environment. PalattenWerk’s environmental policy covers the
                efficient and economical use of raw materials and energy and the
                minimisation of the negative environmental impact of production
                processes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Effective management <br /> of raw materials
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                The wooden pallets made by PalettenWerk are a fully ecological,
                durable, and reusable product. The pallets which cannot be
                repaired are processed into other products, such as pellets or
                sawdust. The wood used in the production of pallets comes
                exclusively from maintenance felling and sawmill waste.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Energy saving <br /> and recovery
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                PalettenWerk have created numerous technological innovations in
                the field of energy saving and recovery. Sawdust- and bark-fired
                ovens are used in the production process, and the heat generated
                during the combustion process, as well as during pallet drying,
                is partially recovered and recycled.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Ethical sourcing <br /> of raw materials
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                The products made by PalettenWerk are manufactured exclusively
                from raw material obtained from sustainably managed forests.
                They are made in accordance with ecological, social, and ethical
                standards. This is confirmed by the PEFC certificate we obtained
                in 2015.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default environmentalProtection;
